<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer
      v-if="$store.state.keyDrawer > 0"
      :key="$store.state.keyDrawer"
    />

    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->
    <chat-button />
  </v-app>
</template>

<script>
//import { mapActions } from "vuex";
import { mapState, mapActions } from "vuex";
export default {
  name: "DashboardIndex",

  data: () => ({
    expandOnHover: false,
  }),

  methods: {
    ...mapActions({
      signIn: "login",
      signOut: "logout",
    }),
    /*...mapActions({
								      signIn: "login",
								      signOut: "logout",
								    }),*/
    /*getUser() {
								      this.signIn();
								    },*/
  },
  created() {
    this.$cv("colorPrimary");
    this.signIn();
    //this.getUser();
  },
  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    //DashboardCoreSettings: () => import('./components/core/Settings'),
    DashboardCoreView: () => import("./components/core/View"),
    ChatButton: () => import("./components/core/ChatButton.vue"),
  },
};
</script>
